const Types = {
  SET_CURRENT_CATEGORY: "SET_CURRENT_CATEGORY",
}

export const Creators = {
  setCurrentCategory: category => ({
    type: Types.SET_CURRENT_CATEGORY,
    payload: category,
  }),
}

const INITIAL_STATE = {
  currentCategory: null,
}

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case Types.SET_CURRENT_CATEGORY:
      return { ...state, currentCategory: payload }
    default:
      return state
  }
}
